import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Button,Menu,Submenu,MenuItem,MenuItemGroup, Drawer} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.component(Button.name, Button);
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(Drawer.name, Drawer); 

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
