<template>
  <div class="container">
    <div class="header flex-space-evenly">
      <img src="../assets/logo_dark.svg" class="logo"/>
		<div class="meun">
			<el-menu
			class="el-menu-demo" 
			mode="horizontal" 
			@select="handleSelect"
			background-color="#060B27"
			>
				<el-submenu index="1">
					<template slot="title">About</template>
					<el-menu-item index="orderType">Order Type</el-menu-item>
					<el-menu-item index="VIPProgram">VIP Program</el-menu-item>
					<el-menu-item index="FAQ">FAQ</el-menu-item>
				</el-submenu>
				<el-menu-item index="Referrals">Referrals</el-menu-item>
				<el-submenu index="3">
					<template slot="title">Community</template>
					<el-menu-item index="Twitter">Twitter</el-menu-item>
					<el-menu-item index="Telegram">Telegram</el-menu-item>
					<el-menu-item index="Discord">Discord</el-menu-item>
				</el-submenu>
				<el-menu-item index="Support">Support</el-menu-item>
			</el-menu>
		</div>
		<div class="button flex align-items-center">
          <el-button round  @click="trade('trade')">Trade</el-button>
		</div>
    </div>
	<div class="content flex-column align-items-center">
		<p>Empowering the Decentralized <br/>Revolution</p>			
		<p>Join the revolution of secure and private trading, trade with confidence, while protecting your data.<br/> 
Our anonymous decentralized crypto exchange empowers you to take control of <br/>
your investments while protecting your financial freedom.</p>
        <img src="../assets/Ikbal.png">
        <table border="0" style=" border-spacing:10px;">
          <tr>
            <th>
				<div class="card">
					<img src="../assets/Group83.svg">
					<p>Liquidity</p>
					<p>Enjoy an exceptional order book liquidity that enables seamless and efficient trading with minimal price deviation.</p>
				</div>
			</th>
            <th>
				<div class="card font-Poppins">
					<img src="../assets/Group84.svg">
					<p>Decentralized</p>
					<p>No need for a middleman, simply link your DeFi wallet and start trading.</p>
				</div>
			</th>
          </tr>
          <tr>
            <th>
				<div class="card font-Poppins">
					<img src="../assets/Group85.svg">
					<p>High Performance</p>
					<p>Transactions are swiftly carried out and verified on the blockchain within a matter of hours.</p>
				</div>
			</th>
            <th>
				<div class="card font-Poppins">
					<img src="../assets/Group86.svg">
					<p>Support for multiple blockchains</p>
					<p>Enhances platform experience through integration of Binance Chain and Ethereum Mainnet.</p>
				</div>
			</th>
          </tr>
		</table>  
		<div class="tradeBut">
			<el-button  round @click="trade()">Trade Now</el-button>
		</div>
		<img src="../assets/screenshoot.svg" style="margin-top: 40px;">
		<div class="chainList  flex-column align-items-center">
			<div class="flex flex-space-between">
				<div class="chain flex align-items-center justify-content-center" v-for="item in chainList[0]" :key=item>
					<img :src="require('../assets/'+item+'.svg')" />
					<p >{{item}}</p>
				</div>
			</div>
			<div class="flex flex-space-between" style="margin-top: 16px;">
				<div class="chain flex align-items-center justify-content-center" v-for="item in chainList[1]" :key=item>
					<img :src="require('../assets/'+item+'.svg')" />
					<p >{{item}}</p>
				</div>
			</div>
			<div class="flex flex-space-between" style="margin-top: 16px;">
				<div class="chain flex align-items-center justify-content-center" v-for="item in chainList[2]" :key=item>
					<img :src="require('../assets/'+item+'.svg')" />
					<p >{{item}}</p>
				</div>
			</div>
		</div>
		<div class="backerList">
			<p>Backed by</p>
			<div class="flex-space-between">
				<div class="backer flex justify-content-center align-items-center">
					<img src="../assets/binance.svg">
				</div>
			
				<div class="backer flex justify-content-center align-items-center">
					<img src="../assets/apollox.svg">
				</div>
				<div class="backer flex justify-content-center align-items-center">
					<img src="../assets/openc.svg">
				</div>
			</div>
		</div>
	</div>
	<div class="foot flex flex-space-evenly">
		<img src="../assets/logo_dark_foot.svg" class="logo"/>
		<div class="flex-column about" >
          <span>About</span>
          <p style="margin-top: 40px;" @click="jump('DEIMOSToken')">Deimos Token</p>
          <p  @click="jump('ReferralProgram')">Referral Program</p>
          <p @click="jump('orderType')">Order Type</p>
          <p @click="jump('Blog')">Blog</p>
		</div>
		<div class="flex-column support"  >
          <span>Support</span>
          <p style="margin-top: 40px;" @click="jump('FAQ')">FAQ</p>
          <p  @click="jump('SubmitTicket')">Submit a ticket</p>
          <p @click="jump('TermsofUse')">Terms of Use</p>
		</div>
		<div class="contact-us flex-column"  >
			<p>Connect with us</p>
			<div class="contact-icon  flex-space-between">
				<div @click="jump('Twitter')">
                  <img src="../assets/twitter.svg" > 
				</div>
				<div @click="jump('Telegram')">
                  <img src="../assets/Vector.svg" >
				</div>
				<div @click="jump('Discord')">
                 <img src="../assets/Vector1.svg" >
				</div>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
export default {
  name: 'AppIndex',
  props: {
    msg: String
  },
  data() {
    return {
		/* chainList:[
			['XRP','TRON-TRX','Solana','Polygon','Polkadot','NEAR','Litecoin'],
			['Ethereum','Dodecoin','Cosmos','ChainLink','Cardano','BNB'],
			['Bitcoin','Avalanche','ApeCoin','Algorand','AaVe']
		], */
		chainList:[
			['XRP','TRX','SOL','MATIC','DOT','NEAR','LTC'],
			['ETH','DOGE','ATOM','LINK','ADA','BNB'],
			['BTC','AVAX','APE','ALGO','AAVE']
		],
		meun:[
			{
				name:"About",
				selected: false,
				url: "https://docs.google.com/document/d/1ZeT-E7AUgY_A2s9unmDKqSn-KPUZ_qpgP_EUx3-ZwKs/edit?usp=sharing",
				isBlank:true
			},
			{
				name:"Affiliates",
				selected: false
			},
			{
				name:"Community",
				selected: false
			},
			{
				name:"Support",
				selected: false
			}
		],
		meunDict: {
			orderType :"https://deimosdex.gitbook.io/deimos-order-type/",
			VIPProgram: "https://deimosdex.gitbook.io/deimos-vip-program/",
			FAQ: "https://deimosdex.gitbook.io/faq/",
			Referrals: "https://deimosdex.gitbook.io/deimos-referral-program/",
			Twitter: "https://twitter.com/DeimosDEX",
			Telegram: "https://t.me/DeimosDEX ",
			Discord: "https://deimosdex.gitbook.io/deimos-discord/",
			Support: "https://t.me/DeimosDEX",
			DEIMOSToken: "https://deimosdex.gitbook.io/deimos-token/",
			ReferralProgram:"https://deimosdex.gitbook.io/deimos-referral-program/",
			Blog:"https://medium.com/@deimosdex",
			SubmitTicket: "https://t.me/DeimosDEX",
			TermsofUse: "https://deimosdex.gitbook.io/terms-of-use/",
			
		}
	}
  },
  methods: {
    open(url,is_blank) {
      if (is_blank){
        window.open(url,"_blank")
      }
    },
	handleSelect(e){
		if (this.meunDict[e]){
			this.open(this.meunDict[e],true)
		}		
	},
	jump(e){
		if (this.meunDict[e]){
			this.open(this.meunDict[e],true)
		}
	},
	trade(){
		this.$router.push({name:"apollox"})
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

.container {
	width: 100;
	height: 2939px;
	background:#060B27  url("../assets/backgroung.svg") no-repeat;
	padding-top: 20px;
}
.header{
	width: 100%;
	height: 64px;
	.meun {
		height: 40px;
		width: 40%;
		::v-deep .el-menu{
			border: none;
		}
		::v-deep .el-menu--horizontal>.el-menu-item {
			color: #ffffff;
			font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif;
			font-size: 14px;
			font-weight: 700;
			opacity: 0.8;
			&:hover {
				opacity: 0.6;
			}
		}
		::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
			color: #ffffff;
			font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif;
			font-size: 14px;
			font-weight: 700;
			opacity: 0.8;
			height:35px;
			&:hover {
				opacity: 0.6;
			}
		}

		::v-deep .el-submenu.is-active .el-submenu__title {
			border-bottom-color: #060B27 !important;
		}
		::v-deep .el-menu--horizontal>.el-menu-item.is-active {
			border-bottom-color: #060B27 !important;
		}
	}
	.button{
		height: 100%;
		::v-deep .el-button{
			background-color: #2BBDC4;
			height: 46px;
			width: 135px;
			border: none;
			font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif;
			font-style: normal;
			font-weight: 800;
			font-size: 16px;
			line-height: 16px;
			color: #FFFFFF;
			&:hover{
				background-color: #2394AD;
			}
			&.is-round {
				border-radius: 36px;
			}
		}
	}
}


.content {
	width: 100%;
	&>:nth-child(1){
		font-size: 64px;
		font-weight: 700;
		color: #ffffff;
	}
	&>:nth-child(2){
		font-size: 18px;
		font-weight: 400;
		margin-top: -30px;
		line-height: 28px;
		width: 869px;
		color: #E0E0E0;
	}
	
	&>:nth-child(3){
		width: 774px;
		height: 532px;
	}
	.card {
		box-sizing: border-box;
		width: 600px;
		height: 242px;
		background: rgba(255, 255, 255, 0.05);
		border: 2px solid rgba(255, 255, 255, 0.1);
		border-radius: 16px;
		text-align: left;
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 30px;
		&>:nth-child(2){
			font-size: 24px;
			font-weight: 700;
			margin-top: 30px;
			color: #FFFFFF;
		}
		&>:nth-child(3){
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			opacity: 0.6;
			margin-top: -16px;
			color: #FFFFFF;
		}
	}
	.tradeBut {
		margin-top: 80px;
		::v-deep .el-button{
			background-color: #2BBDC4;
			height: 60px;
			width:200px;
			border: none;
			font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF;
			box-shadow: 4px 4px 0px rgba(43, 189, 196, 0.4);
			border-radius: 46.9565px;
			&:hover{
				background-color: #2394AD;
			}
			&.is-round {
				border-radius: 36px;
			}
		}
	}
	.chainList{
		
		margin-top: 32px;
		&>:nth-child(1){
			width: 1214px;
		}
		&>:nth-child(2){
			width: 1038px;
		}
		&>:nth-child(3){
			width: 862px;
		}
		.chain {
			width: 158px;
			height: 80px;	
			background: rgba(255, 255, 255, 0.05);
			border: 1.18343px solid rgba(255, 255, 255, 0.1);
			border-radius: 59.1716px;
			color: #FFFFFF;
			& img {
				width: 32px;
				height: 32px;
			}
			& p {
				margin-left: 8px;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	.backerList {
		width: 930px;
		& p{
			font-size: 64px;
			font-weight: 700;
			color: #FFFFFF;
		}
		.backer {
			box-sizing: border-box;
			width: 294px;
			height: 120px;
			background: rgba(255, 255, 255, 0.05);
			border: 2px solid rgba(255, 255, 255, 0.1);
			border-radius: 16px;
			& img {
				width: 245px;
				height: 48px;
			}
		}
	}
}
.foot {
	background-color: #05091F;
	width: 100%;
	height: 320px;
	margin-top: 150px;
	padding-top: 60px;
	.logo {
		width: 145px;
		height: 64px;
		opacity: 0.6;
	}
	.about{
		color: #F2F2F2;
		&:hover {
			cursor:pointer;
		}
		& span{
			font-size: 20px;
			font-weight: 700;
			opacity: 0.6;
		}
		& p{
			font-size: 16px;
			font-weight: 400;
			opacity: 0.6;
			&:hover {
				opacity:0.8;
			}
			
		}
	}
	.support{
		color: #F2F2F2;
		&:hover {
			cursor:pointer;
		}
		& span{
			font-size: 20px;
			font-weight: 700;
			opacity: 0.6;
		}
		& p{
			font-size: 16px;
			font-weight: 400;
			opacity: 0.6;
			&:hover {
				opacity:0.8;
			}
			
		}
	}
	
	.contact-us {
		font-weight: 700;
		font-size: 32px;		
		color: #FFFFFF;		
		opacity: 0.6;
		.contact-icon {
			width: 336px;
			margin-top: 20px;
			&:hover {
				cursor:pointer;
			}
			& div {
				width: 96px;
				height: 64px;	
				background: #1e2135;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				
			}
		}
	
	}

}
.flex-space-between{
	display: flex;
	justify-content: space-between;
}
.flex-space-evenly{
	display: flex;
	justify-content: space-evenly;
}
.flex-column{
	display: flex;
	flex-direction: column;
}
.flex {
	display: flex;
}
.align-items-center{
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
</style>
