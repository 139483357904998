<template>
  <div class="container">
	<div class="header flex-space-between align-items-center">
		<img src="../assets/logo_dark.svg" class="logo"/>
		<img src="../assets/meun.svg" class="meun" @click="drawer=true"/>
	</div>
	<div class="content">
		<p>Empowering the <br/> Decentralized</p>
		<p>Revolution</p>
		<div>
			<p>Join the revolution of secure and private trading, trade with confidence, while protecting your data.
			Our anonymous decentralized crypto exchange empowers you to take control of 
			your investments while protecting your financial freedom.</p>
		</div>
	
        <img src="../assets/Ikbal.svg">
		<div class="tradeBut">
			<el-button  round @click="trade('trade')">Trade Now</el-button>
		</div>
	</div>
	<div class="foot">
		<div class="flex flex-space-between" >
			<div class="about">
				<span>About</span>
				<p style="margin-top: 30px;" @click="jump('DEIMOSToken')">Deimos Token</p>
				<p @click="jump('ReferralProgram')">Referral Program</p>
				<p @click="jump('orderType')">Order Type</p>
				<p @click="jump('Blog')">Blog</p>
			</div>
			<div class="support">
				<span>Support</span>
				<p style="margin-top: 30px;" @click="jump('FAQ')">FAQ</p>
				<p @click="jump('SubmitTicket')">Submit a ticket</p>
				<p @click="jump('TermsofUse')">Terms of Use</p>
			</div>
		</div>
		<div >
			Connect with us
		</div>
		<div class="flex">
			<div @click="jump('Twitter')">
				<img src="../assets/twitter.svg" style="width: 30px; height: 25px;">
			</div>
			<div @click="jump('Telegram')">
				<img src="../assets/Vector.svg" style="width: 30px; height: 30px;">
			</div>
			<div @click="jump('Discord')">
				<img src="../assets/Vector1.svg" style="width: 26px; height: 29px;">
			</div>
		</div>
	</div>
	<el-drawer
		:visible.sync="drawer "
		direction="ltr"
		size="60%">
		<div>
			<img src="../assets/logo_dark.svg" />
			<el-menu
			@select="handleSelect">
				<el-submenu index="1">
					<template slot="title">
						<span>About</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="orderType">Order Type</el-menu-item>
						<el-menu-item index="VIPProgram">VIP Program</el-menu-item>
						<el-menu-item index="FAQ">FAQ</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-menu-item index="Referrals">
					<span slot="title" >Referrals</span>
				</el-menu-item>
				<el-submenu index="3">
					<template slot="title">Community</template>
					<el-menu-item index="Twitter">Twitter</el-menu-item>
					<el-menu-item index="Telegram">Telegram</el-menu-item>
					<el-menu-item index="Discord">Discord</el-menu-item>
				</el-submenu>
				<el-menu-item index="Support">Support</el-menu-item>
			</el-menu>
		</div>
	</el-drawer>
  </div>
</template>

<script>
export default {
  name: 'AppIndex',
  props: {
    msg: String
  },
  data() {
    return {
		drawer : false,
		meun:[
			{
				name:"About",
				selected: false,
				url: "https://docs.google.com/document/d/1ZeT-E7AUgY_A2s9unmDKqSn-KPUZ_qpgP_EUx3-ZwKs/edit?usp=sharing",
				isBlank:true
			},
			{
				name:"Affiliates",
				selected: false
			},
			{
				name:"Community",
				selected: false
			},
			{
				name:"Support",
				selected: false
			}
		],
		meunDict: {
			orderType :"https://deimosdex.gitbook.io/deimos-order-type/",
			VIPProgram: "https://deimosdex.gitbook.io/deimos-vip-program/",
			FAQ: "https://deimosdex.gitbook.io/faq/",
			Referrals: "https://deimosdex.gitbook.io/deimos-referral-program/",
			Twitter: "https://twitter.com/DeimosDEX",
			Telegram: "https://t.me/DeimosDEX ",
			Discord: "https://deimosdex.gitbook.io/deimos-discord/",
			Support: "https://t.me/DeimosDEX",
			DEIMOSToken: "https://deimosdex.gitbook.io/deimos-token/",
			ReferralProgram:"https://deimosdex.gitbook.io/deimos-referral-program/",
			Blog:"https://medium.com/@deimosdex",
			SubmitTicket: "https://t.me/DeimosDEX",
			TermsofUse: "https://deimosdex.gitbook.io/terms-of-use/",
		}
	}
  },
  methods: {
    open(url,is_blank) {
      if (is_blank){
        window.open(url,"_blank")
      }
    },
	handleSelect(e){
		if (this.meunDict[e]){
			this.open(this.meunDict[e],true)
		}		
	},
	jump(e){
		if (this.meunDict[e]){
			this.open(this.meunDict[e],true)
		}
	},
	trade(){
		this.$router.push({name:"apollox"})
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

.container {
	width: 100%;
	height: 1080px;
	background:#060B27  url("../assets/backgroung_phone.svg") no-repeat;
	padding-top: 20px;
}
.header{
	width: 100%;
	height: 64px;
	box-sizing: border-box;
	padding: 0px 20px ;
	.logo {
		width: 145px;
		height: 64px;
	}
	.meun{
		width: 36px;
		height: 36px;
	}

}

.content {
	width: 100%;
	&>:nth-child(1){
		font-size: 24px;
		font-weight: 700;
		color: #ffffff;
	}
	&>:nth-child(2){
		font-size: 44px;
		font-weight: 700;
		color: #ffffff;
		margin: 0;
	}
	
	&>:nth-child(3){
		width: 100%;
		box-sizing: border-box;
		padding: 0px 20px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #E0E0E0;
		
	}

	.tradeBut {
		margin-top: 15px;
		::v-deep .el-button{
			background-color: #2BBDC4;
			height: 54px;
			width:180px;
			border: none;
			font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF;
			box-shadow: 4px 4px 0px rgba(43, 189, 196, 0.4);
			border-radius: 46.9565px;
			&:hover{
				background-color: #2394AD;
			}
			&.is-round {
				border-radius: 36px;
			}
		}
	}
}
.foot {
	background-color: #00000033;
	width: 100%;
	height: 334px;
	margin-top: 40px;
	&>:nth-child(1){
		box-sizing: border-box;
		padding: 0 20px;
		text-align: left;
		.about {
			color: #F2F2F2;
			width: 140px;
			& span{
				font-size: 18px;
				font-weight: 700;
				opacity: 0.6;
			}
			& p{
				font-size: 14px;
				font-weight: 400;
				opacity: 0.6;
			}
		}
		.support{
			color: #F2F2F2;
			width: 140px;
			& span{
				font-size: 18px;
				font-weight: 700;
				opacity: 0.6;
			}
			& p{
				font-size: 14px;
				font-weight: 400;
				opacity: 0.6;
			}
		}
	}
	&>:nth-child(2){
		font-weight: 700;
		font-size: 20px;
		color: #FFFFFF;
		opacity: 0.6;
		padding-left: 20px;
		text-align: left;
		margin-top: 32px;
	}
	&>:nth-child(3){
		margin-top: 24px;
		& div {
			width: 72px;
			height: 48px;	
			background: #1e2135;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 20px;
			img {
				opacity: 0.6;
			}
		}
		
	}

}
.flex-space-between{
	display: flex;
	justify-content: space-between;
}
.flex-space-evenly{
	display: flex;
	justify-content: space-evenly;
}
.flex-column{
	display: flex;
	flex-direction: column;
}
.flex {
	display: flex;
}
.flex-start{
	justify-content: flex-start;
}
.align-items-center{
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
::v-deep .el-drawer{
	background-color:#060B27
}
::v-deep .el-menu {
	text-align: left;
	background-color:#060B27
}
::v-deep .el-submenu__title {
	font-size: 16px;
	color: rgb(240, 240, 245);
	&:hover {
		background-color:#060B27
	}
}
::v-deep .el-menu-item {
	color: rgb(240, 240, 245);
}
::v-deep .el-menu-item.is-active{
	color: rgb(240, 240, 245);
}
::v-deep .el-submenu__title:focus, .el-submenu__title:hover {
	background-color:#060B27
}
::v-deep .el-menu-item:focus, .el-menu-item:hover {
	background-color:#060B27
}
</style>
