<template>
	<div id='apollox'>
	</div>
</template>

<script>
export default {
	data() {
		return {
			headerConfig: {
				logo:{
					lightImgUrl: "static/images/share-poster/light_logo.svg",
					darkImgUrl: "static/images/share-poster/dark_logo.svg"
				},
				menu: {
					data: [
						{
							text: {
								'en': 'Home',
								'zh-CN': '主页',
								'zh-TW':'主頁',
								'de':'Home',
								'uk-UA':'Home',
								'ru':'Home',
								'fr':'Home',
								'ko':'Home',
								'pt-BR':'Home',
								'es':'Home',
								'es-LA':'Home',
								'th':'Home',
								'ja':'Home',
								'pl':'Home',
								'tr':'Home',
								
							},
							navTo: 'https://deimosx.io/',
							navTarget:  "_parent"
						},
						{
							text: {
								'en': 'Perpetuals',
								'zh-CN': '合约交易',
								'zh-TW':'永續',
								'de':'Perpetuals',
								'uk-UA':'Безстрокові',
								'ru':'Бессрочные',
								'fr':'Perpétuels',
								'ko':'무기한',
								'pt-BR':'Perpétuos',
								'es':'Perpetuos',
								'es-LA':'Perpetuos',
								'th':'Perpetual',
								'ja':'永久',
								'pl':'Kontrakty Perpetual',
								'tr':'Uzun Vadeli',
							},
							navTo: 'https://deimosx.io/trade',
							navTarget:  "_parent"
						},
					
							
					]
				}
				
			},
			darkPalette: {
				primaryHover: "#2BBDC4", // background hover
				primary: "#2BBDC4", // background; text hover,
				bg2: "#ffffff",
				bg1: "#ffffff",
				sellHover: "#FD5CB7",
				sell: "#EF3E9E",
				buyHover: "#3CC6BE",
				moduleBg: "#221f27",
				popupBg:"#221f27",
				badgeYellowBg:"#2c2832",
				//slider: {
				//line: "#000000"
				//}
			},
			lightPalette: {
				primaryHover: "#2BBDC4", // background hover
				primary: "#2BBDC4", // background; text hover,
				sellHover: "#FD5CB7",
				sell: "#EF3E9E",
				buyHover: "#3CC6BE",
				// overallBg: "#000000"
			},
			pcPage: false,
		};
	},
  methods: {
},
  mounted() {
	const { origin, protocol } = window.location;
	const isDev = protocol === "http:";
	window.apolloxSdk.createTradeUI({
		container: document.getElementById("apollox"),
		config: {
			staticBaseUrl: '/sdk/cloud_sdk.1.9.2/static/', 
			futuresWsHost: "wss://fstream.apollox.finance/compress",
			apiBaseUrl: isDev ? `${origin}` : undefined, // in production, you don't need to configurate this, it's default as APX's url
			headerConfig: this.headerConfig,
			darkPalette: this.darkPalette,
			lightPalette: this.lightPalette,
			defaultTheme: "dark",
			supportNetworks: ['bnb','eth'],
			//isTestnet: true,
			state: {
				symbol: "BTCUSDT",
				lng: "en",
			}, 
			fontFamily:"Roboto, Arial, PingFangSC-Regular, 'Microsoft YaHei', sans-serif"
		},
		});

	}
}	
</script>

<style>
	#apollox {
		width: 100%;
		height: 100%;
	}
</style>