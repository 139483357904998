<template>
  <div id="app">
	<router-view />
  </div>
</template>

<script>



export default {
  name: 'App',

  data() {
    return {
  
    };
  },


}
</script>

<style>

#app {
	font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif;
	
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
  height: 100vh;
  
}
 .el-menu--horizontal .el-menu .el-menu-item {
		color: #ffffff !important;
		font-family: Roboto, Arial, PingFangSC-Regular, "Microsoft YaHei", sans-serif !important;
		opacity: 0.8;
		padding-left: 20px !important;
}
 .el-menu--horizontal .el-menu .el-menu-item:hover {
	opacity: 0.6 !important;
 }
.el-menu--horizontal .el-menu .el-menu-item.is-active{
	color: #ffffff !important;
}

</style>
