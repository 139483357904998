<template>
  <div>
    <AppIndex  v-if="pcPage"/> 
	<phone v-if="mobile"/>
  </div>
</template>

<script>
import AppIndex from '../components/AppIndex.vue' 
import phone from '../components/phone.vue'

export default {
  components: {
	phone,
	AppIndex
  },
  data() {
    return {
      mobile: false,
      pcPage: false,
    };
  },
  methods: {
	widthChange() {
		if (document.body.clientWidth > 768) {
			this.mobile = false;
			this.pcPage = true;
		} else {
			this.pcPage = false;
			this.mobile = true;
		}
	}
},
  created() {
	this.widthChange()
	window.onresize = () => {
		this.widthChange();
	};
	}
}
</script>


