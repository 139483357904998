import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomePage.vue'
import apollox from '../views/ApolloxSdk.vue'
Vue.use(VueRouter)

const routes = [
	{
	path: '/',
	name: 'Home',
	component: Home
	} ,
	{
		path: '/trade',
		name: 'apollox',
		component: apollox
	} 
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
